import React, {Component} from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

interface TypeformProps {
  embedLink: string;
}

class Typeform extends Component<TypeformProps> {
  constructor(props) {
    super(props)
  }
  

  componentDidMount() {
    let qs,js,q,s,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/";
    if(!gi.call(d,id)) {
      js=ce.call(d,"script");
      js.id=id;
      js.src=b+"embed.js";
      q=gt.call(d,"script")[0];
      q.parentNode.insertBefore(js,q)
    }
  }

  render () {
    return (
      <div className="typeform-widget" data-url={this.props.embedLink} style={{width: '100%', height: '100vh'}}></div>
    )
  }
}

// ""

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.description}/>
      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
      {frontmatter.typeformEmbedLink && <Typeform embedLink={frontmatter.typeformEmbedLink} /> }
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
        description
        typeformEmbedLink
      }
    }
  }
`